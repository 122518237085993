import CCP_TransitionImageChild from './TransitionImageChild'
import { Skeleton } from '@mui/material'

const DoctorImage = ({
  isLarge = false,
  addedClass = '',
  url,
  isLoading = false,
}) => {
  return (
    <div className="tw-p-1.5 tw-shadow-main-blue tw-bg-white tw-rounded-full tw-h-min tw-w-min tw-flex-none">
      <div
        className={`tw-relative ${addedClass} ${
          isLarge
            ? 'md:tw-size-[280px] sm:tw-size-[220px] tw-size-32'
            : 'sm:tw-size-32 tw-size-[28vw]'
        } tw-rounded-full tw-overflow-hidden`}
      >
        {isLoading ? (
          <Skeleton className="!tw-w-full !tw-h-full !tw-absolute !tw-transform-none" />
        ) : (
          <CCP_TransitionImageChild
            alt="Doctor Image"
            src={url}
            transition="object-position"
          />
        )}
      </div>
    </div>
  )
}

export default DoctorImage
