import { Link } from '@/navigation'
import { useTranslations } from 'next-intl'

export default function SCP_MoreInformation({
  data,
  addedClass = '',
  isAppointmentPage,
  currentCenterSlug = '',
  currentCenterGroupSlug = '',
}) {
  const __t = useTranslations()
  if (!data) return null
  const center = {
    icon: 'far fa-hospital',
    title: data.full_place_title,
    detail: data.location,
    moreDetail: data.office_hours,
  }
  const tels = data.phone
    ? data.phone.map((x) => ({
        icon: 'far fa-phone-square-alt',
        label: x.display,
        link: `tel:${x.call_out}`,
      }))
    : []
  const contacts = [
    ...tels,
    {
      icon: 'far fa-fax',
      label: data.fax,
      link: `tel:${data.fax}`,
    },
    {
      icon: 'far fa-envelope',
      label: data.email,
      link: `mailto:${data.email}`,
    },
    {
      icon: 'fab fa-line',
      label: data.line.id_display,
      link: data.line.link,
      addedClass: 'group-hover:!tw-text-line',
    },
  ].filter((x) => x.label)
  const information = {
    center,
    contacts,
  }
  if (!information.center.title && information.contacts.length === 0)
    return null
  // Center slug
  if (currentCenterSlug && currentCenterGroupSlug) {
    information.center.link = `/center-clinic/${currentCenterGroupSlug}/${currentCenterSlug}`
  } else if (currentCenterGroupSlug) {
    information.center.link = `/center-clinic/${currentCenterGroupSlug}`
  } else {
    information.center.link = ''
  }

  return (
    <div
      id="centerContact"
      className={`tw-pt-8 tw-mt-8 max-md:tw-pb-8 tw-border-t-[3px] tw-border-black/5 ${addedClass}`}
    >
      {!isAppointmentPage && <h4>{__t('for-more-information')}</h4>}

      <div className="tw-grid md:tw-grid-cols-2 sm:tw-gap-8 tw-gap-4 tw-mt-4">
        {/* Left */}
        {information.center.title ? (
          <div>
            {/* Center */}
            <Link
              prefetch={false}
              href={information.center.link}
              className={`tw-flex tw-items-center tw-space-x-4 ${information.center.link ? 'tw-cursor-pointer' : 'tw-pointer-events-none'}`}
            >
              <i
                className={`tw-text-bgh-blue tw-text-xl ${information.center.icon}`}
              />
              <h5 className="tw-text-bgh-blue">{information.center.title}</h5>
            </Link>
            {/* Detail */}
            <p
              className="tw-ml-10 tw-my-4"
              dangerouslySetInnerHTML={{ __html: information.center.detail }}
            />
            <p
              className="tw-ml-10 tw-text-sm"
              dangerouslySetInnerHTML={{
                __html: information.center.moreDetail,
              }}
            />
          </div>
        ) : null}
        {/* Right */}
        <div className="tw-flex tw-flex-col tw-space-y-2">
          {information.contacts.map((c, i) => (
            <Link
              prefetch={false}
              href={c.link}
              key={`contact-${i}`}
              className="tw-flex tw-items-center tw-space-x-4 tw-group"
            >
              <i
                className={`group-hover:tw-text-bgh-blue tw-text-xl ${c.icon} ${c.addedClass}`}
              />
              <p
                className={`group-hover:tw-text-bgh-blue ltr:group-hover:tw-translate-x-1 rtl:group-hover:-tw-translate-x-1 tw-duration-200 ${c.addedClass}`}
              >
                {c.label}
              </p>
            </Link>
          ))}
        </div>
      </div>
    </div>
  )
}
