import { Chip, Skeleton } from '@mui/material'

export default function SCP_BlueChip({
  label,
  size = 'small',
  icon,
  onClick = null,
  addedClass,
  isLoading = false,
}) {
  if (!label) return
  return (
    <>
      {isLoading ? (
        <Skeleton variant="rounded" width={80} height={24} />
      ) : (
        <Chip
          color="bgh-blue-alpha"
          className={`${addedClass} !tw-text-bgh-blue !tw-font-bold [&_span]:!tw-whitespace-normal max-sm:!tw-leading-[1.7] !tw-text-left !tw-h-full ${
            onClick ? 'hover:!tw-bg-bgh-blue/20' : ''
          } ${
            size === 'medium'
              ? 'tw-text-sm !tw-py-1.5 !tw-px-3'
              : '!tw-py-1  !tw-px-2'
          }`}
          size={size}
          label={label}
          icon={icon ? icon : <></>}
          onClick={onClick}
        />
      )}
    </>
  )
}
